.home {

	&-logo {

		padding: 68px 0 28px;

		&__list {

			display: flex;
			align-items: center;
			justify-content: flex-start;

			&.owl-carousel .owl-stage-outer {
				padding-bottom: 40px;
			}

			&.owl-carousel .owl-stage {
				display: flex;
    			align-items: center;
			}

			&-item {

				& img {
					display: block;
					width: 300px;
				}

				&.--magnit-extra {

					position: relative;

					& .extra-logo {

						position: absolute;
						bottom: -50px;
						right: 0;

						& img {
							width: 196px;
						}

					}

				}

			}

		}

	}

	&-gameplay {

		padding: 55px 0 80px;
		/*background-color: hsla(144,0%,100%,1);
		background-image:
			radial-gradient(at 25% 60%, hsla(157,93%,50%,1) 0px, transparent 50%),
			radial-gradient(at 49% 0%, hsla(78,100%,50%,1) 0px, transparent 50%),
			radial-gradient(at 0% 0%, hsla(99,100%,40%,1) 0px, transparent 50%),
			radial-gradient(at 58% 99%, hsla(332,53%,63%,1) 0px, transparent 50%),
			radial-gradient(at 100% 100%, hsla(0,100%,58%,1) 0px, transparent 50%),
			radial-gradient(at 100% 1%, hsla(54,100%,52%,1) 0px, transparent 50%),
			radial-gradient(at 0% 100%, hsla(164,40%,49%,1) 0px, transparent 50%);*/
		background-size: 100% 100%;
		background-image: url('../images/gradient_bg_1.png');

		&__title {
			
			margin-bottom: 70px;
			text-align: center;

			& span {
				display: inline-block;
				padding: 48px 123px 40px;
				border: 6px solid #FFFFFF;
				font-weight: 700;
				font-size: 55px;
				line-height: 61px;
				text-align: center;
				text-transform: uppercase;
				color: $white;
				-webkit-border-top-left-radius: 40px;
				-webkit-border-bottom-right-radius: 40px;
				-moz-border-radius-topleft: 40px;
				-moz-border-radius-bottomright: 40px;
				border-top-left-radius: 40px;
				border-bottom-right-radius: 40px;
			}

		}

		&__post-title {
			font-weight: 700;
			font-size: 55px;
			line-height: 61px;
			text-transform: uppercase;
			color: $white;
			text-align: center;
			max-width: 865px;
			margin: 0 auto 13px;
		}

		&__post-description {
			font-weight: 400;
			font-size: 24px;
			line-height: 26px;
			color: $white;
			text-align: center;
		}

		&__list {

			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: center;
			margin-bottom: 66px;

			&-item {

				flex: 0 0 408px;
				margin-right: 50px;

				&:last-child {
					margin-right: 0;
				}

				& .list-item {

					&__picture {

						/*margin-bottom: 40px;*/
						height: 333px;

						& picture,
						& picture img {
							max-width: 100%;
							max-height: 333px;
							display: inline-block;
						}

						&.--car {

							padding-top: 27px;
							position: relative;
							display: flex;
							align-items: center;
							justify-content: center;

							& picture,
							& picture img {
								display: inline-block;
							}

						}
					}

					&__title {
						font-weight: 700;
						font-size: 32px;
						line-height: 41px;
						margin-bottom: 22px;
					}

					&__content {

						display: flex;
						flex-wrap: wrap;
						align-items: flex-start;
						justify-content: flex-start;

						&-num {

							display: flex;
							flex-wrap: wrap;
							align-items: center;
							justify-content: center;
							flex: 0 0 116px;
							height: 116px;
							border: 6px solid #FFFFFF;
							padding: 18px 0;
							-webkit-border-top-left-radius: 40px;
							-webkit-border-bottom-right-radius: 40px;
							-moz-border-radius-topleft: 40px;
							-moz-border-radius-bottomright: 40px;
							border-top-left-radius: 40px;
							border-bottom-right-radius: 40px;
							margin-right: 20px;
							
							& span {
								display: block;
								max-width: 100%;
								height: 100%;
								& img {
									display: inline-block;
									max-width: 100%;
									height: 100%;
								}
							}

						}

						&-text {
							flex: 1;
							font-weight: 400;
							font-size: 24px;
							line-height: 26px;
							color: $white;
						}

					}

				}

			}

		}

	}

	&-main-prize {

		padding: 60px 0 88px;
		background-size: 100% 100%;
		background-image: url('../images/gradient_bg_2.png');

		&__title {
			font-weight: 700;
			font-size: 160px;
			line-height: 176px;
			text-align: center;
			text-transform: uppercase;
			color: $white;
		}

		&__car {

			position: relative;
			top: -60px;
			text-align: center;

			& picture,
			& picture img {
				max-width: 100%;
			}

			&-lights {

				position: absolute;
				top: 0;
				left: 0;
				z-index: 100;
				width: 100%;
    			height: 100%;

				.car-lights {

					&__left {

						position: absolute;
						top: -89px;
    					left: 551px;
						z-index: 100;

						& picture,
						& picture img {
							display: block;
						}

					}

					&__right {

						position: absolute;
						top: -41px;
						left: 855px;
						z-index: 100;

						& picture,
						& picture img {
							display: block;
						}

					}

				}

			}

		}

		&__second-title {
			font-weight: 700;
			font-size: 55px;
			line-height: 61px;
			text-align: center;
			text-transform: uppercase;
			color: $white;
			position: relative;
			top: -35px;
			color: $white;
		}

		&__list {

			&.owl-theme.--home-prizes {

				 & .owl-dots {
					margin-top: 22px;
				}

				 & .owl-dots .owl-dot span {
					width: 10px;
					height: 10px;
					margin: 5px 8px;
					background: $white;
					display: block;
					-webkit-backface-visibility: visible;
					border-radius: 30px;
					outline: none;
				}

				& .owl-dots .owl-dot.active span,
				& .owl-dots .owl-dot:hover span {
					background: $white;
					transform: scale(2);
				}

				& .owl-nav button.owl-prev,
				& .owl-nav button.owl-next {
					background: none;
					color: inherit;
					border: none;
					padding: 0 !important;
					font: inherit;
				}

				& .owl-nav {
					margin-top: 0;
					height: 0px;
				}

				& .owl-nav button.owl-prev .owl__nav-left,
				& .owl-nav button.owl-next .owl__nav-right {
					width: 37px;
					height: 62px;
					background: url('../img/carousel-left-arrow.svg') 0 0 no-repeat;
					position: absolute;
					top: 66px;
					left: 0;
				}

				& .owl-nav button.owl-next .owl__nav-right {
					background: url('../img/carousel-right-arrow.svg') 0 0 no-repeat;
					right: 0;
					left: auto;
				}

			}

			&-item {

				width: 260px;
				margin: 0 auto;

				& .list-item {

					text-align: center;

					&__picture {

						text-align: center;

						& picture,
						& picture img {
							display: inline-block;
							max-width: 100%;
							height: 195px;
						}

						&.--b-30 {
							position: relative;
							bottom: -30px;
						}

						&.--b-45 {
							position: relative;
							bottom: -45px;
						}
						
					}

					&__title {

						background-color: $red;
						-webkit-border-top-left-radius: 40px;
						-webkit-border-bottom-right-radius: 40px;
						-moz-border-radius-topleft: 40px;
						-moz-border-radius-bottomright: 40px;
						border-top-left-radius: 40px;
						border-bottom-right-radius: 40px;
						width: 100%;
						height: 83px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 17px;
						position: relative;
						z-index: 100;

						& span {
							position: relative;
    						top: 3px;
							font-weight: 700;
							font-size: 24px;
							line-height: 26px;
							text-align: center;
							text-transform: uppercase;
							color: $white;
						}

					}

					&__quantity {
						font-weight: 400;
						font-size: 24px;
						line-height: 26px;
						text-align: center;
					}

				}

			}

		}

		&__button {
			margin-top: 45px;
			text-align: center;
		}

	}

	&-win-prize {

		padding: 54px 0 30px;
		overflow: hidden;

		&__title {

			padding: 26px 70px;
			background: $red;
			-webkit-border-top-left-radius: 40px;
			-webkit-border-bottom-right-radius: 40px;
			-moz-border-radius-topleft: 40px;
			-moz-border-radius-bottomright: 40px;
			border-top-left-radius: 40px;
			border-bottom-right-radius: 40px;
			margin: 0 auto 40px;
			text-align: center;
			max-width: 813px;

			position: relative;

			& span {
				position: relative;
				top: 3px;
				font-weight: 700;
				font-size: 48px;
				line-height: 53px;
				text-align: center;
				text-transform: uppercase;
				color: $white;
			}

			&-picture {

				position: absolute;
				z-index: 100;

				&.--left {
					left: 0;
					bottom: -200px;
					-webkit-transform: translateX(-59%);
					   -moz-transform: translateX(-59%);
					    -ms-transform: translateX(-59%);
					     -o-transform: translateX(-59%);
					        transform: translateX(-59%);
				}

				&.--right {
					right: 0;
    				bottom: -135px;
					-webkit-transform: translateX(70%);
					   -moz-transform: translateX(70%);
					    -ms-transform: translateX(70%);
					     -o-transform: translateX(70%);
					        transform: translateX(70%);
				}

			}

		}

		&__button {
			text-align: center;
			margin-bottom: 30px;
		}

		&__text {
			text-align: center;
			margin: 0 auto;
			width: 336px;
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: $darkgrey;
		}

	}

}



@media screen and (max-width: 1440px) {

	.home {

		&-gameplay {

			&__list {

				margin-bottom: 66px;

				&-item {

					flex: 0 0 332px;
					margin-right: 50px;

					&:last-child {
						margin-right: 0;
					}

					& .list-item {

						&__picture {

							margin-bottom: 15px;
							height: 300px;

							& picture,
							& picture img {
								max-height: 300px;
							}

							&.--car {

								padding-top: 27px;
								position: relative;
								display: flex;
								align-items: center;
								justify-content: center;

								& picture,
								& picture img {
									display: inline-block;
								}

							}
						}

						&__title {
							font-weight: 700;
							font-size: 32px;
							line-height: 41px;
							margin-bottom: 22px;
						}

						&__content {

							&-num {

								flex: 0 0 106px;
								height: 106px;
								margin-right: 18px;
								
								& span {
								}

							}

							&-text {
								font-size: 21px;
								line-height: 25px;
							}

						}

					}

				}

			}

		}

		&-main-prize {

			&__car {

				&-lights {

					.car-lights {

						&__left {
							top: -89px;
							left: 427px;
						}

						&__right {
							top: 48px;
							left: 809px;
						}

					}

				}

			}

		}

		&-win-prize {

		}

	}

}

@media screen and (max-width: 1170px) {

	.home {

		&-gameplay {

			&__list {

				margin-bottom: 66px;

				&-item {

					flex: 0 0 33.3%;
					margin-right: 0px;

					& .list-item {

						&__title {
							font-size: 29px;
							line-height: 22px;
							margin-bottom: 20px;
						}

						&__content {

							&-num {

								flex: 0 0 80px;
								height: 80px;
								margin-right: 16px;
								-webkit-border-top-left-radius: 20px;
								-webkit-border-bottom-right-radius: 20px;
								-moz-border-radius-topleft: 20px;
								-moz-border-radius-bottomright: 20px;
								border-top-left-radius: 20px;
								border-bottom-right-radius: 20px;
								
								& span {
								}

							}

							&-text {
								font-size: 18px;
								line-height: 22px;
							}

						}

					}

				}

			}

		}

		&-main-prize {

			&__car {

				&-lights {

					.car-lights {

						&__left {
							top: -57px;
							left: 370px;
						}

						&__right {
							top: 82px;
							left: 741px;
						}

					}

				}

			}

		}

		&-win-prize {

			&__title {

				&-picture {

					max-width: 278px;

					& picture,
					& picture img {
						max-width: 100%;
					}

					position: absolute;
					z-index: 100;

					&.--left {
						left: 0;
						bottom: -136px;
						-webkit-transform: translateX(-56%);
						   -moz-transform: translateX(-56%);
						    -ms-transform: translateX(-56%);
						     -o-transform: translateX(-56%);
						        transform: translateX(-56%);
					}

					&.--right {
						right: 30px;
						bottom: -107px;
						-webkit-transform: translateX(63%);
						   -moz-transform: translateX(63%);
						    -ms-transform: translateX(63%);
						     -o-transform: translateX(63%);
						        transform: translateX(63%);
					}

				}

			}

		}

	}

}

@media screen and (max-width: 992px) {

	.home {

		&-gameplay {

			&__title {
				
				margin-bottom: 0px;

				& span {
					display: inline-block;
					padding: 38px 103px;
					font-weight: 700;
					font-size: 55px;
					line-height: 61px;
					-webkit-border-top-left-radius: 40px;
					-webkit-border-bottom-right-radius: 40px;
					-moz-border-radius-topleft: 40px;
					-moz-border-radius-bottomright: 40px;
					border-top-left-radius: 40px;
					border-bottom-right-radius: 40px;
				}

			}

			&__post-title {
				font-size: 45px;
				line-height: 50px;
				max-width: 860px;
				margin: 0 auto 13px;
			}

			&__list {

				margin-bottom: 66px;

				&-item {

					flex: 0 0 100%;
					text-align: center;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}

					& .list-item {

						&__title {
							font-size: 32px;
							line-height: 41px;
							margin-bottom: 22px;
						}

						&__picture {

							margin-bottom: 0px;
							height: 333px;

							& picture,
							& picture img {
								max-height: 333px;
							}

							&.--car {
								padding-top: 57px;
							}
						}

						&__content {

							justify-content: center;

							&-num {

								flex: 0 0 116px;
								height: 116px;
								margin-right: 20px;
								-webkit-border-top-left-radius: 40px;
								-webkit-border-bottom-right-radius: 40px;
								-moz-border-radius-topleft: 40px;
								-moz-border-radius-bottomright: 40px;
								border-top-left-radius: 40px;
								border-bottom-right-radius: 40px;
								
								& span {
								}

							}

							&-text {
								font-size: 24px;
								line-height: 26px;
								text-align: left;
								max-width: 290px;
							}

						}

					}

				}

			}

		}

		&-main-prize {

			&__title {
				font-size: 100px;
				line-height: 106px;
			}

			&__car {

				top: -30px;

				&-lights {

					.car-lights {

						&__left {
							top: -45px;
							left: 271px;
						}

						&__right {
							top: 64px;
							left: 556px;
						}

					}

				}

			}

		}

		&-win-prize {
		}

	}

}

@media screen and (max-width: 768px) {

	.home {

		&-gameplay {

			&__title {
				
				margin-bottom: 0px;

				& span {
					display: inline-block;
					padding: 33px 21px;
					font-weight: 700;
					font-size: 40px;
					line-height: 44px;
					-webkit-border-top-left-radius: 40px;
					-webkit-border-bottom-right-radius: 40px;
					-moz-border-radius-topleft: 40px;
					-moz-border-radius-bottomright: 40px;
					border-top-left-radius: 40px;
					border-bottom-right-radius: 40px;
				}

			}

			&__post-title {
				font-size: 33px;
				line-height: 37px;
				max-width: 100%;
				margin: 0 auto 13px;
			}

		}

		&-main-prize {

			&__car {

				top: -30px;

				&-lights {

					.car-lights {

						&__left {
							top: -38px;
							left: 192px;
						}

						&__right {
							top: 48px;
							left: 415px;
						}

					}

				}

			}

		}

		&-win-prize {

			&__title {

				padding: 26px 80px;
				margin: 0 auto 70px;

				& span {
					font-size: 30px;
					line-height: 33px;

					& br {
						display: none;
					}

				}

				&-picture {

					max-width: 220px;

					&.--left {
						bottom: -104px;
						-webkit-transform: translateX(-41%);
						   -moz-transform: translateX(-41%);
						    -ms-transform: translateX(-41%);
						     -o-transform: translateX(-41%);
						        transform: translateX(-41%);
					}

					&.--right {
						bottom: -57px;
						-webkit-transform: translateX(41%);
						   -moz-transform: translateX(41%);
						    -ms-transform: translateX(41%);
						     -o-transform: translateX(41%);
						        transform: translateX(41%);
					}

				}

			}

		}

	}

}

@media screen and (max-width: 576px) {

	.home {

		&-logo {

			padding: 38px 0 28px;

			/*&__list {

				&.owl-carousel .owl-stage-outer {
					padding-bottom: 40px;
				}

				&-item {

					& img {
						max-width: 200px;
					}

					&.--magnit-extra {

						& .extra-logo {

							bottom: -50px;

							& img {
								width: 196px;
							}

						}

					}

				}

			}*/

		}

		&-gameplay {

			&__title {
				
				margin-bottom: 0px;

				& span {
					display: inline-block;
					padding: 33px 11px 24px;
					font-weight: 700;
					font-size: 35px;
					line-height: 40px;
					-webkit-border-top-left-radius: 40px;
					-webkit-border-bottom-right-radius: 40px;
					-moz-border-radius-topleft: 40px;
					-moz-border-radius-bottomright: 40px;
					border-top-left-radius: 40px;
					border-bottom-right-radius: 40px;
				}

			}

			&__post-title {
				font-size: 30px;
				line-height: 33px;
				max-width: 100%;
				margin: 0 auto 31px;
			}

			&__post-description {
				font-size: 20px;
				line-height: 22px;
			}

			&__list {

				&-item {

					text-align: left;

					& .list-item {

						&__picture {

							text-align: center;

							&.--car {

								padding-top: 0px;
								max-height: 233px;

							}

						}

						&__title {
							font-size: 32px;
							line-height: 41px;
							margin-bottom: 22px;
						}

						&__content {

							justify-content: flex-start;

							&-num {

								flex: 0 0 100px;
								height: 100px;
								margin-right: 16px;
								-webkit-border-top-left-radius: 30px;
								-webkit-border-bottom-right-radius: 30px;
								-moz-border-radius-topleft: 30px;
								-moz-border-radius-bottomright: 30px;
								border-top-left-radius: 30px;
								border-bottom-right-radius: 30px;
								
								& span {
								}

							}

							&-text {
								font-weight: 400;
								font-size: 20px;
								line-height: 24px;
								max-width: 100%;
							}

						}

					}

				}

			}

		}

		&-main-prize {

			&__title {
				font-size: 63px;
				line-height: 76px;
				text-align: right;
			}


			&__car {

				top: -30px;
				width: 336px;
				margin: 0 auto;

				& > picture,
				& > picture img {
					width: 336px;
				}

				&-lights {

					.car-lights {

						&__left {
							top: -38px;
							left: 100px;
						}

						&__right {
							top: 31px;
							left: 269px;
						}

					}

				}

			}

			&__second-title {
				font-size: 38px;
				line-height: 43px;
				top: -20px;
			}

		}

		&-win-prize {

			&__title {

				padding: 26px 31px;
				margin: 0 auto 70px;

				& span {
					font-size: 30px;
					line-height: 33px;

					& br {
						display: none;
					}

				}

				&-picture {

					max-width: 178px;

					&.--left {
						bottom: -76px;
						-webkit-transform: translateX(-36%);
						   -moz-transform: translateX(-36%);
						    -ms-transform: translateX(-36%);
						     -o-transform: translateX(-36%);
						        transform: translateX(-36%);
					}

					&.--right {
						bottom: -57px;
						-webkit-transform: translateX(45%);
						   -moz-transform: translateX(45%);
						    -ms-transform: translateX(45%);
						     -o-transform: translateX(45%);
						        transform: translateX(45%);
					}

				}

			}
		}

	}

}

@media screen and (min-width: 576px) {

	.home {

		/*&-gameplay {

			&__list {

				&-item {

					& .list-item {

						&__content {

							&-num {
								
								& span {
									line-height: 1.25;
								}

							}

						}

					}

				}

			}

		}*/

	}

}

@media screen and (min-width: 768px) {

	.home {

		&-main-prize {

			&__car {

				&-lights {

					.car-lights {

						&__left {
							top: -45px;
							left: 271px;
						}

						&__right {
							top: 64px;
							left: 556px;
						}

					}

				}

			}

		}

	}

}

@media screen and (min-width: 992px) {

	.home {

		&-main-prize {

			&__car {

				&-lights {

					.car-lights {

						&__left {
							top: -57px;
							left: 370px;
						}

						&__right {
							top: 82px;
							left: 741px;
						}

					}

				}

			}

		}

	}

}

@media screen and (min-width: 1170px) {

	.home {

		/*&-gameplay {

			&__list {

				&-item {

					& .list-item {

						&__content {

							&-num {
								
								& span {
									line-height: 1.25;
								}

							}

						}

					}

				}

			}

		}*/

		&-main-prize {

			&__car {

				&-lights {

					.car-lights {

						&__left {
							top: -89px;
							left: 427px;
						}

						&__right {
							top: 48px;
							left: 809px;
						}

					}

				}

			}

		}

	}

}

@media screen and (min-width: 1440px) {

	.home {

		/*&-gameplay {

			&__list {

				&-item {

					& .list-item {

						&__content {

							&-num {
								
								& span {
									line-height: 1.27;
								}

							}

						}

					}

				}

			}

		}*/

		&-main-prize {

			&__car {

				&-lights {

					.car-lights {

						&__left {
							top: -89px;
							left: 551px;
						}

						&__right {
							top: -41px;
    						left: 855px;
						}

					}

				}

			}

		}

	}

}
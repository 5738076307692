.winners {

	&-container	{
		padding: 55px 0 45px;
		background-size: 100% 100%;
		background-image: url('../images/gradient_bg_1.png');
	}

	&__table-header {

		& .table-header {

			&__row {
				display: flex;
				/*flex-wrap: wrap;*/
				align-items: center;
				justify-content: flex-start;
				padding: 22px 0 18px;
				background: $red;
				-webkit-border-top-left-radius: 40px;
				-webkit-border-bottom-right-radius: 40px;
				-moz-border-radius-topleft: 40px;
				-moz-border-radius-bottomright: 40px;
				border-top-left-radius: 40px;
				border-bottom-right-radius: 40px;
			}

			&__col {
				flex: 0 0 25%;
				text-align: center;
				font-weight: 600;
				font-size: 24px;
				line-height: 26px;
				text-align: center;
				text-transform: uppercase;
				color: $white;
				padding: 0 10px;

				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}

		}

	}

	&__table-body {

		margin-bottom: 44px;
		min-height: 300px;
		position: relative;

		& > .loader {				
			display: none;
			width: 100px;
			height: 100px;
			position: absolute;
			z-index: 120;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			   -moz-transform: translate(-50%, -50%);
			    -ms-transform: translate(-50%, -50%);
			     -o-transform: translate(-50%, -50%);
			        transform: translate(-50%, -50%);
		}

		&.--loading {

			& > .loader {				
				display: block;
			}			

		}

		& > .no-data {		
			display: none;
			font-weight: 600;
			font-size: 24px;
			line-height: 26px;
			text-align: center;
			text-transform: uppercase;
			color: #FFFFFF;		
		}

		&.--no-data {

			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;

			& > .no-data {		
				display: block;			
			}

		}

		& .table-body {

			&__row {

				display: flex;
				/*flex-wrap: wrap;*/
				align-items: center;
				justify-content: flex-start;
				padding: 22px 0 18px;

				&:nth-child(odd) {
					background: rgba(255, 255, 255, .2);
				}

			}

			&__col {
				flex: 0 0 25%;
				text-align: center;
				font-weight: 600;
				font-size: 24px;
				line-height: 26px;
				text-align: center;
				text-transform: uppercase;
				color: $white;
				padding: 0 10px;

				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}

		}

	}

	&__buttons {

		text-align: center;

		& a.--white {
			margin-left: 25px;
		}

	}

}

@media screen and (max-width: 1440px) {

	.winners {

		&__table-header {

			& .table-header {

				&__col {
					font-weight: 600;
					font-size: 24px;
					line-height: 26px;
				}

			}

		}

		&__table-body {

			& .table-body {

				&__col {
					font-weight: 600;
					font-size: 24px;
					line-height: 26px;
				}

			}

		}

	}

}

@media screen and (max-width: 1170px) {

	.winners {

		&__table-header {

			& .table-header {

				&__col {
					font-weight: 600;
					font-size: 20px;
					line-height: 22px;
				}

			}

		}

		&__table-body {

			& .table-body {

				&__col {
					font-weight: 600;
					font-size: 20px;
					line-height: 22px;
				}

			}

		}

	}

}

@media screen and (max-width: 992px) {

	.winners {

		&__table-header {

			& .table-header {

				&__col {
					font-weight: 600;
					font-size: 18px;
					line-height: 20px;
				}

			}

		}

		&__table-body {

			& .table-body {

				&__col {
					font-weight: 600;
					font-size: 18px;
					line-height: 20px;
				}

			}

		}

	}

}

@media screen and (max-width: 768px) {

	.winners {

		&__table-header {
			display: none;
		}

		&__table-body {

			& .table-body {

				&__row {

					flex-wrap: wrap;
					padding: 21px 24px;

					&:nth-child(even) {
						background: rgba(0, 195, 92, .2);
					}

				}

				&__col {

					flex: 0 0 100%;
					font-weight: 600;
					font-size: 20px;
					line-height: 22px;
					margin-bottom: 15px;
					text-align: left;

					text-overflow: inherit;
					white-space: normal;
					overflow: auto;

					&:last-child {
						margin-bottom: 0;
					}

				}

			}

		}

		&__buttons {

			display: flex;
			flex-direction: column;
			width: 320px;
			margin: 0 auto;


			& a.--white {
				margin-left: 0;
				margin-top: 25px;
			}

		}

	}

}

@media screen and (max-width: 576px) {

	.winners {

		&__buttons {
			width: 300px;
		}

	}

}
.header {

	background: rgba(255, 255, 255, 0.01);
	box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.05);
	padding: 28px 0;

	& .hamburger,
	&__logo {
		display: none;
	}

	&__logo {

		margin-left: 40px;

		&:first-child {
			margin-left: 0;
		}

	}

	&.--hero {
		position: absolute;
		z-index: 600;
		width: 100%;
		top: 0;
		left: 0;
		-webkit-backdrop-filter: blur(9px);
		backdrop-filter: blur(9px);
	}
	
	&__nav {

		margin: 0 0 0 90px;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;

		&-item {

			margin: 0 30px 0 0;
			padding: 0;
			list-style: none;

			& a {

				font-weight: 700;
				font-size: 18px;
				line-height: 23px;
				color: $white;
				text-decoration: none;
				-webkit-transition: opacity .25s ease;
				   -moz-transition: opacity .25s ease;
				    -ms-transition: opacity .25s ease;
				     -o-transition: opacity .25s ease;
				        transition: opacity .25s ease;

				&:hover {
					opacity: 0.6;
				}

			}

			&.--active {

				border-bottom: solid 2px $white;

				& a {

					&:hover {
						cursor: default;
						opacity: 1;
					}

				}

			}

		}

	}

}

@media screen and (max-width: 1170px) {

	.header {

		&__nav {
			margin: 0;
		}

	}

}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 768px) {

	.header {

		padding: 9px 0;

		&.--hero {
			position: fixed;
			z-index: 900;
		}

		& > .container {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-start;
		}

		&__logo {
			display: block;
		}

		& .hamburger {

			display: block;
			margin-left: auto;

			& .hamburger-inner {
				margin-top: 1px;
			}

		}

		&__nav {

			display: none;
			margin-top: 20px;

			&-item {

				margin: 0 0 20px 0;

				& a {

					font-weight: 700;
					font-size: 24px;
					line-height: 31px;
					color: $white;
					text-decoration: none;

					&:hover {
						opacity: 0.6;
					}

				}

				&.--active {

					border-bottom: none;

					& a {

						&:hover {
							cursor: default;
							opacity: 1;
						}

					}

				}

			}

		}

	}

	.--nav-show .header {

		height: 100%;
		background: rgba(0, 0, 0, 0.27);

		&.--hero {
			-webkit-backdrop-filter: blur(13px);
			backdrop-filter: blur(13px);
		}

		&__nav {
			width: 100%;
			display: block;
		}

	}

}

.header-hero {

	height: 100%;
	min-height: 650px;
	background-image: url('../images/hero/hero_bg--2560@2x.png');
	background-size: 100% 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;		
	border-bottom: solid 4px white;
	/*overflow: hidden;*/

	&__content {

		position: relative;
		z-index: 100;

		& .content {

			&__logos {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-start;
				margin-bottom: 57px;

				& div {
					margin-left: 90px;
				}

				& div + div {
					margin-right: 90px;
					margin-left: auto;
				}

			}

			&__title {
				text-align: center;
				margin-bottom: 36px;
			}

		}

	}

	.home & {

		align-items: flex-end;
		border-bottom: none;
		height: 833px;

	}

	.home &__background {

		position: absolute;
		z-index: 100;
		width: 100%;

		& picture,
		& picture img {
			width: 100%;
			height: 833px;
		}

	}

	.home &__content {

		bottom: 54px;

		/*position: absolute;
		z-index: 100;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		   -moz-transform: translate(-50%, -50%);
		    -ms-transform: translate(-50%, -50%);
		     -o-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);*/

		& .content {

			&__text {

				position: relative;

				&-col {

					&.--left {
						position: absolute;
						top: 0;
						left: 0;
					}

					&.--center {
						margin: 0 auto;
						position: relative;
						z-index: 100;
						text-align: center;
						padding-top: 21px;
					}

					&.--right {
						position: absolute;
						top: 0;
						right: 0;
					}

					& .text-col {

						&__content {
							position: absolute;
							z-index: 100;							
							top: 50%;
							left: 50%;
							-webkit-transform: translate(-50%, -50%);
							   -moz-transform: translate(-50%, -50%);
							    -ms-transform: translate(-50%, -50%);
							     -o-transform: translate(-50%, -50%);
							        transform: translate(-50%, -50%);
							font-weight: 700;
							font-size: 32px;
							line-height: 35px;
							text-align: center;
							color: $white;
							width: 320px;
						}

						&__car-light {

							position: absolute;
							z-index: 100;
							top: 0;
							left: 50%;
							transform: translateX(-50%);
							mix-blend-mode: screen;

							&.--left {
								top: -46px;
								margin-left: 143px;
							}

							&.--right {
								top: -17px;
								margin-left: 261px;
							}

						}

						&__smartphone {

							position: absolute;
							z-index: -1;
							bottom: -66px;
							left: 50%;
							transform: translateX(-50%);
							margin-left: 48px;

							&.--mobile {
								display: none;
							}

						}

						&__smartphone-shadow {
							display: none;
						}

					}

					&.--left .text-col {
						&__content {
							width: 275px;
						}
					}

					&.--right .text-col {
						&__content {
							width: 325px;
						}
					}

				}

			}

		}

	}

	.contacts & {

    	&__content {

	    	position: relative;
			z-index: 100;

			& .content {

				&__text {

					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;
					justify-content: center;

					&-left {
						margin-right: 200px;
					}

					&-right {
						margin-left: 200px;
					}

					& div {

						font-weight: 600;
						font-size: 32px;
						line-height: 41px;
						color: $white;
						margin-bottom: 13px;

						& a {

							text-decoration: underline;
							color: $white;

							&:hover {
								text-decoration: none;
								color: $white;
							}

						}

						&.phone {

							font-weight: 400;
							font-size: 24px;
							line-height: 31px;
							margin-bottom: 0;

							& a {

								text-decoration: none;
								color: $white;

								&:hover {
									text-decoration: underline;
									color: $white;
								}

							}

						}

					}

				}

			}

    	}

	}

	.faq & {

		height: 536px;

		&__content {

			& .content {

				&__title {

					&.--mobile {
						display: none;
					}

				}

				&__text {
					font-weight: 400;
					font-size: 24px;
					line-height: 26px;
					color: $white;
					text-align: center;
				}

			}

		}

	}

	.winners & {

		height: 841px;
		border-bottom: none;

		&__content {

			& form {

				position: relative;

				& > .loader {
					display: none;
					width: 100px;
					height: 100px;
					position: absolute;
					z-index: 120;
					top: 50%;
					left: 50%;
					-webkit-transform: translate(-50%, -50%);
					   -moz-transform: translate(-50%, -50%);
					    -ms-transform: translate(-50%, -50%);
					     -o-transform: translate(-50%, -50%);
					        transform: translate(-50%, -50%);
				}

				&.--loading {

					&:before {
						content: '';
						position: absolute;
						display: block;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						z-index: 110;
					}

					& > .loader {
						display: block;
					}

					& > .content__filter {
						opacity: 0.3;
					}

				}

			}

			& .content {

				&__filter {

					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;

					&-left {
						flex: 0 0 512px;
						padding-right: 33px;
					}

					&-right {
						flex: 0 0 512px;
						padding-left: 33px;

					}

					&-field {

						margin-bottom: 17px;

						&.--search {

							position: relative;

							&:before {
								content: '';
								display: block;
								position: absolute;
								top: 50%;
								left: 22px;
								-webkit-transform: translateY(-50%);
								   -moz-transform: translateY(-50%);
								    -ms-transform: translateY(-50%);
								     -o-transform: translateY(-50%);
								        transform: translateY(-50%);
								z-index: 100;
								width: 30px;
								height: 30px;
								background: url('../img/search-input.svg') 0 0 no-repeat;
							}

						}

						& .select2-container--default .select2-selection--single {
							background-color: $white;
							border: none;
							height: 70px;
							user-select: none;
							-webkit-user-select: none;
							-webkit-border-top-left-radius: 20px;
							-webkit-border-bottom-right-radius: 20px;
							-moz-border-radius-topleft: 20px;
							-moz-border-radius-bottomright: 20px;
							border-top-left-radius: 20px;
							border-bottom-right-radius: 20px;
						}

						& .select2-container--default.select2-container--open .select2-selection--single {
							-webkit-border-bottom-right-radius: 0px;
							-moz-border-radius-bottomright: 0px;
							border-bottom-right-radius: 0px;
						}

						& .select2-container--default.select2-container--above.select2-container--open .select2-selection--single {
							-webkit-border-top-left-radius: 0px;
							-webkit-border-bottom-right-radius: 20px;
							-moz-border-radius-topleft: 0px;
							-moz-border-radius-bottomright: 20px;
							border-top-left-radius: 0px;
							border-bottom-right-radius: 20px;
						}

						& .select2-container .select2-selection--single .select2-selection__rendered {
							padding: 24px 22px 20px 22px;
						}

						& .select2-container--default .select2-selection--single .select2-selection__rendered {
							font-weight: 600;
							font-size: 24px;
							line-height: 26px;
							color: $black;
						}

						& .select2-container--default .select2-selection--single .select2-selection__arrow {
							width: 26px;
							height: 15px;
							position: absolute;
							top: 50%;
							right: 22px;
							-webkit-transform: translateY(-50%);
							   -moz-transform: translateY(-50%);
							    -ms-transform: translateY(-50%);
							     -o-transform: translateY(-50%);
							        transform: translateY(-50%);
							background: url('../img/select-arrow-down.svg') 0 0 no-repeat;
						}

						& .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
							-webkit-transform: translateY(-50%) rotate(180deg);
							   -moz-transform: translateY(-50%) rotate(180deg);
							    -ms-transform: translateY(-50%) rotate(180deg);
							     -o-transform: translateY(-50%) rotate(180deg);
							        transform: translateY(-50%) rotate(180deg);
						}

						& .select2-container--default .select2-selection--single .select2-selection__arrow b {
							display: none;
						}

						/*& .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
						    border-color: transparent transparent #888 transparent;
						    border-width: 0 4px 5px 4px;
						}*/

						& .filter-field {

							&__select {

							}

							&__input,
							&__input:focus {
								background: $white;
								width: 100%;
								max-width: 512px;
								padding: 24px 55px 20px 55px;
								text-align: center;
								font-weight: 600;
								font-size: 24px;
								line-height: 26px;
								color: $black;
								-webkit-border-top-left-radius: 20px;
								-webkit-border-bottom-right-radius: 20px;
								-moz-border-radius-topleft: 20px;
								-moz-border-radius-bottomright: 20px;
								border-top-left-radius: 20px;
								border-bottom-right-radius: 20px;
								outline: none;
								border: none;
								user-select: none;
								-webkit-user-select: none;
							}

						}

					}

					&-description {
						font-weight: 400;
						font-size: 18px;
						line-height: 20px;
						color: $white;
					}

					&-submit {
						text-align: center;
						flex: 0 0 100%;
						margin-top: 46px;
					}

				}

			}

		}

	}

}

@media screen and (max-width: 1440px) {

	.header-hero {

		.home &__content {

			& .content {

				&__text {

					&-col {

						&.--left,
						&.--right {

							width: 340px;

							& picture,
							& picture img {
								max-width: 100%;
								height: auto;
							}

						}

						&.--left .text-col {
							&__content {
								width: 205px;
							}
						}

						&.--right .text-col {
							&__content {
								width: 295px;
							}
						}

						&.--center {

							margin: 0 auto;
							position: relative;
							z-index: 100;
							text-align: center;
							padding-top: 24px;

							& > picture,
							& > picture img {
								max-width: 648px;
								height: auto;
							}

						}

						& .text-col {

							&__content {
								font-size: 28px;
    							line-height: 32px;
							}

							&__car-light {

								&.--left picture,
								&.--left picture img,
								&.--right picture,
								&.--right picture img {
									max-width: 100%;
								}

								&.--left {
								    top: -22px;
    								margin-left: 127px;
									max-width: 400px;
								}

								&.--right {
									top: 22px;
    								margin-left: 235px;
									max-width: 210px;
								}

							}

							&__smartphone {

								bottom: -64px;
    							margin-left: 14px;
								width: 900px;

								& > picture,
								& > picture img {
									max-width: 100%;
									height: auto;
								}

							}

						}

					}

				}

			}

		}

		.contacts & {

	    	&__content {

				& .content {

					&__text {

						&-left {
							margin-right: 200px;
						}

						&-right {
							margin-left: 150px;
						}

					}

				}

	    	}

		}

	}

}

@media screen and (max-width: 1170px) {

	.header-hero {

		/*height: 100%;
		min-height: 650px;	*/	

		.home & {
			height: 712px;
		}

		&__content {

			& .content {

				&__logos {
					margin-bottom: 50px;

					& div {
						margin-left: 0px;
					}

					& div + div {
						margin-right: 0px;
					}

				}

				&__title {
					margin-bottom: 32px;
				}

			}

		}

		.home &__content {

			& .content {

				&__text {

					&-col {

						&.--left,
						&.--right {

							width: 275px;
							top: 0px;

							& picture,
							& picture img {
								max-width: 100%;
								height: auto;
							}

						}

						&.--left .text-col {
							&__content {
								width: 205px;
							}
						}

						&.--right .text-col {
							&__content {
								width: 250px;
							}
						}

						&.--center {

							padding-top: 0px;

							& > picture,
							& > picture img {
								max-width: 550px;
								height: auto;
								position: relative;
								top: 11px;
							}

						}

						& .text-col {

							&__content {
								font-size: 20px;
    							line-height: 25px;
							}

							&__car-light {

								&.--left {
								    top: -1px;
    								margin-left: 101px;
									max-width: 300px;
								}

								&.--right {
									top: 36px;
    								margin-left: 205px;
									max-width: 150px;
								}

							}

							&__smartphone {

								bottom: -63px;
    							margin-left: 14px;
								width: 800px;

								& > picture,
								& > picture img {
									max-width: 100%;
									height: auto;
								}

							}

						}

					}

				}

			}

		}

		.contacts & {

	    	&__content {

				& .content {

					&__text {

						&-left {
							margin-right: 60px;
						}

					}

				}

	    	}

		}

		.winners & {

			&__content {

				& .content {

					&__filter {

						&-left {
							flex: 0 0 512px;
							padding-right: 0px;
							margin-bottom: 30px;
						}

						&-right {
							flex: 0 0 512px;
							padding-left: 0px;

						}

					}

				}

			}

		}

	}

}

@media screen and (max-width: 992px) {

	.header-hero {

		.home & {
			height: 600px;
			min-height: 600px;
		}

		&__content {

			& .content {

				&__logos {

					margin-bottom: 40px;

					& div {

						width: 315px;

						& img {
							max-width: 100%;
						}

					}

					& div + div {

						text-align: right;

						& img {
							max-width: 70%;
						}

					}

				}

				&__title {
					margin-bottom: 32px;
				}

			}

		}

		.home &__content {

			& .content {

				&__text {

					&-col {

						&.--left,
						&.--right {

							width: 208px;
							top: 0px;

						}

						&.--left .text-col {
							&__content {
								width: 125px;
							}
						}

						&.--right .text-col {
							&__content {
								width: 155px;
							}
						}

						&.--center {

							padding-top: 0px;

							& > picture,
							& > picture img {
								max-width: 470px;
								top: 11px;
							}

						}

						& .text-col {

							&__content {
								font-size: 15px;
    							line-height: 17px;
							}

							&__car-light {

								&.--left {
								    top: 5px;
    								margin-left: 90px;
								}

								&.--right {
									top: 22px;
    								margin-left: 174px;
								}

							}

							&__smartphone {
								bottom: -61px;
								width: 700px;
							}

						}

					}

				}

			}

		}

		.contacts & {

			min-height: 575px;

	    	&__content {

				& .content {

					&__text {

						&-left {
							margin-right: 0px;
						}

						&-right {
							margin-left: 150px;
						}

						& div {

							font-size: 24px;
							line-height: 31px;

						}

					}

				}

	    	}

		}

	}

}

@media screen and (max-width: 768px) {
	

	.header-hero {

		.home & {
			/*height: 484px;
			min-height: 484px;*/
			height: 812px;
    		min-height: 812px;
    		align-items: center;
    		overflow: hidden;
		}

		&__content {

			& .content {

				&__logos {
					display: none;
				}

				&__title {
					margin-bottom: 32px;
				}

			}

		}

		.home &__content {

			bottom: -88px;

			& .content {

				&__text {

					position: relative;
					display: flex;
					flex-wrap: wrap;

					&-col {

						position: relative;

						&.--left {

							position: relative;
							order: 2;
							background: url('../images/hero/hero_text_box_green@2x.png') 0 0 no-repeat;
							background-size: 100% 100%;
							width: 337px;
							height: 191px;
							display: flex;
							align-items: center;
							justify-content: flex-end;
							left: -128px;
							top: 21px;

							& picture {
								display: none;
							}

						}

						&.--center {
							margin: 0 auto;
							position: relative;
							z-index: 100;
							text-align: center;
							padding-top: 0;
							order: 1;

							& picture,
							& picture img {
								position: relative;
    							z-index: 90;
							}

						}

						&.--right {

							position: relative;
							order: 3;							
							background: url('../images/hero/hero_text_box_red@2x.png') 0 0 no-repeat;
							background-size: 100% 100%;
							margin-left: auto;
							width: 339px;
							height: 192px;
							display: flex;
							align-items: center;
							justify-content: center;
							right: -67px;
							top: -101px;

							& picture {
								display: none;
							}

						}

						& .text-col {

							&__content {
								position: static;
								z-index: 100;							
								top: auto;
								left: auto;
								-webkit-transform: none;
								   -moz-transform: none;
								    -ms-transform: none;
								     -o-transform: none;
								        transform: none;
								font-weight: 700;
								font-size: 32px;
								line-height: 35px;
								text-align: center;
								color: $white;
								width: 320px;
							}

							&__car-light {

								top: auto;
								left: auto;
								transform: none;
								mix-blend-mode: screen;

								&.--left {
									top: -8px;
    								margin-left: 183px;
								}

								&.--right {
									top: 35px;
    								margin-left: 347px;
								}

							}

							&__smartphone {

								display: none;

								&.--mobile {
									display: block;
									z-index: 10;
								}

							}

							&__smartphone-shadow {
								display: block;
								position: absolute;
								z-index: -1;
								top: 159px;
								right: -138px;
							}



						}

						&.--left .text-col,
						&.--right .text-col {

							&__content {
								width: 100%;
								font-weight: 700;
								font-size: 24px;
								line-height: 27px;
								text-align: left;
   								max-width: 212px;
							}
							
						}

						&.--right .text-col {

							&__content {
								max-width: 250px;
							}

						}

					}

				}

			}

		}

		.faq & {

			background-image: url('../images/gradient_bg_mobile.png');			
			height: 484px;
			min-height: 484px;

		}

		.contacts & {

			background-image: url('../images/gradient_bg_mobile.png');

	    	&__content {

				& .content {

					&__text {

						&-left {
							flex: 0 0 100%;
							width: 100%;
							margin-right: 0px;
							margin-bottom: 27px;
						}

						&-right {
							flex: 0 0 100%;
							width: 100%;
							margin-left: 0px;
						}

						& div {

							font-size: 24px;
							line-height: 31px;

							& a {

								text-decoration: underline;
								color: $white;

								&:hover {
									text-decoration: none;
									color: $white;
								}

							}

							&.phone {

								font-weight: 400;
								font-size: 24px;
								line-height: 31px;
								margin-bottom: 0;

								& a {

									text-decoration: none;
									color: $white;

									&:hover {
										text-decoration: underline;
										color: $white;
									}

								}

							}

						}

					}

				}

	    	}

		}

		.winners & {

			height: 633px;

			&__content {
			    margin-top: 65px;
			}

		}

	}

}

@media screen and (max-width: 576px) {	

	.header-hero {

		.faq & {
	
			height: 312px;
			min-height: 312px;

	    	&__content {

	    		margin-top: 85px;

				& .content {

					&__title {
						
						display: none;
						margin-bottom: 0;

						&.--mobile {

							display: flex;
							flex-wrap: wrap;

							font-weight: 700;
							font-size: 40px;
							line-height: 51px;
							text-transform: uppercase;
							color: $white;

							& .q {
								flex: 0 0 100%;
								text-align: left;
								margin-bottom: 16px;
							}

							& .a {
								flex: 0 0 100%;
								text-align: right;
							}

						}
						
					}

					&__text {
						display: none;
					}
				}

			}

		}

		.contacts & {

			min-height: 452px;

	    	&__content {

	    		top: 44px;

				& .content {

					&__text {

						& div {

							font-weight: 600;
							font-size: 20px;
							line-height: 26px;
							margin-bottom: 16px;

							&.phone,
							&.email {
								font-weight: 600;
								font-size: 18px;
								line-height: 23px;
								margin-bottom: 11px;
							}

						}

					}

				}

	    	}

		}

		.home & {
			height: 812px;
    		min-height: 812px;
			background-image: url('../images/hero/hero_bg--mobile@2x.png');
		}

		.home &__content {

			bottom: -35px;

			& .content {

				&__title {
					text-align: left;
					margin-bottom: 0;
				}

				&__text {

					position: relative;
					display: flex;
					flex-wrap: wrap;

					&-col {

						position: relative;

						&.--left {
							width: 280px;
							height: 158px;
							align-items: center;
							justify-content: center;
							left: -50px;
							top: 59px;

						}

						&.--center {
							margin: 0 auto;
							position: relative;
							z-index: 100;
							text-align: center;
							padding-top: 0;
							order: 1;

							& > picture,
							& > picture img {
    							max-width: 360px;
							}

						}

						&.--right {

							position: relative;
							order: 3;							
							background: url('../images/hero/hero_text_box_red@2x.png') 0 0 no-repeat;
							background-size: 100% 100%;
							margin-left: auto;
							width: 282px;
							height: 160px;
							display: flex;
							align-items: center;
							justify-content: center;
							right: -50px;
							top: 36px;

							& picture {
								display: none;
							}

						}

						& .text-col {

							&__content {
								position: static;
								z-index: 100;							
								top: auto;
								left: auto;
								-webkit-transform: none;
								   -moz-transform: none;
								    -ms-transform: none;
								     -o-transform: none;
								        transform: none;
								font-weight: 700;
								font-size: 32px;
								line-height: 35px;
								text-align: center;
								color: $white;
								width: 320px;
							}

							&__car-light {

								top: auto;
								left: auto;
								transform: none;
								mix-blend-mode: screen;

								&.--left {
									top: -2px;
    								margin-left: 143px;
								}

								&.--right {
									top: 35px;
    								margin-left: 272px;
								}

							}

							&__smartphone {

								display: none;

								&.--mobile {
									display: block;
									z-index: 10;
									max-width: 550px;
								}

							}

							&__smartphone-shadow {
								display: block;
								position: absolute;
								z-index: -1;
								top: 135px;
								left: 0;
								right: auto;
								width: 397px;

								& picture,
								& picture img {
									max-width: 100%;
								}

							}



						}

						&.--left .text-col,
						&.--right .text-col {

							&__content {
								width: 100%;
								font-weight: 700;
								font-size: 20px;
								line-height: 22px;
								text-align: left;
   								max-width: 160px;
							}
							
						}

						&.--right .text-col {

							&__content {
								max-width: 209px;
							}

						}

					}

				}

			}

		}



		.winners & {

			&__content {

				margin-top: 60px;

				& .content {

					&__filter {

						&-left {
							flex: 0 0 100%;
						}

						&-right {
							flex: 0 0 100%;
						}

						&-field {

							margin-bottom: 11px;

							& .select2-container .select2-selection--single .select2-selection__rendered {
								padding: 27px 22px 21px 22px;
							}

							& .select2-container--default .select2-selection--single .select2-selection__rendered {
								font-size: 20px;
								line-height: 22px;
							}

							& .filter-field {

								&__select {

								}

								&__input,
								&__input:focus {
									padding: 27px 34px 21px 65px;
									font-size: 20px;
									line-height: 22px;
								}

							}

						}

						&-description {
							font-size: 16px;
							line-height: 18px;
						}

						&-submit {
							margin-top: 40px;
						}

					}

				}

			}

		}

	}

}
html, body {

	font-family: 'SB Sans Display', sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	line-height: 1.5;
	color: $standart-text-color;

}

body.--nav-show {
	overflow: hidden;
}

::-webkit-input-placeholder {
    color: $black;
	font-weight: 600;
	font-size: 24px;
	line-height: 26px;
}

::-moz-placeholder {
    color: $black;
	font-weight: 600;
	font-size: 24px;
	line-height: 26px;
}

::-ms-placeholder {
    color: $black;
	font-weight: 600;
	font-size: 24px;
	line-height: 26px;
}

::placeholder {
    color: $black;
	font-weight: 600;
	font-size: 24px;
	line-height: 26px;
}

::selection {
    background: #03c925;
    color: $white;
}

::-webkit-selection {
    background: #03c925;
    color: $white;
}

::-ms-selection {
    background: #03c925;
    color: $white;
}

::-o-selection {
    background: #03c925;
    color: $white;
}

::-moz-selection {
    background: #03c925;
    color: $white;
}

h1, .h1 {
	font-weight: 700;
	font-size: 107px;
	line-height: 118px;
	text-transform: uppercase;
}

.h-100 {
    height: 100% !important;
}

.btn {

	display: inline-block;
	padding: 19px 56px 15px;
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;
	text-align: center;
	text-transform: uppercase;
	text-align: center;
	border-radius: 6px;
	background-color: $red;
	border: 3px solid $red;
	color: $white;
	-webkit-transition: all .25s linear;
	   -moz-transition: all .25s linear;
	    -ms-transition: all .25s linear;
	     -o-transition: all .25s linear;
	        transition: all .25s linear;
	text-decoration: none;

	&:hover {
		background: $darkred;
		border-color: $darkred;
		color: $white;
	}
	
}

.btn.--white {

	background-color: transparent;
	border-color: $white;
	color: $white;

	&:hover {
		color: $red;
		border-color: $white;
		background: $white;
	}
	
}

.home .header.--hero + .header-hero + .main {
	/*margin-top: 179px;*/
}

.parallax-vegetables {

	height: 234px;
	background-color: $white;
	/*background: url('../images/vegetables@1x.png') center 0 no-repeat;*/
	position: relative;
	overflow: hidden;

	/*.winners & {
		background: none;		
	}*/

	& > div {

		position: absolute;
		z-index: 100;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		& picture,
		& picture img {
			max-width: 100%;
		}

		&.veg {

			display: none;
			position: absolute;
			z-index: 200;

			& picture,
			& picture img {
				max-width: 100%;
			}

			&.--1 {
				/*cucumber bottom*/
				width: 385px;
				display: block;
				margin: 412px 0 0 -13px;
				z-index: 210;
			}

			&.--2 {
				/*granat ceil*/
				width: 372px;
				display: block;
				margin: -177px 0 0 -140px;
				
			}

			&.--3 {
				/*bolgarian pepper*/
				width: 478px;
				display: block;
				margin: 140px 0px 0 59px
			}

			&.--4 {
				/*chili pepper*/
				width: 546px;
				display: block;
				margin: 77px 0 0 -290px;
				z-index: 250;
			}

			&.--5 {
				/*leaf bottom and pepper*/
				width: 199px;
				display: block;
				margin: 286px 0 0 239px;
				
			}

			&.--6 {
				/*granat half*/
				width: 422px;
				display: block;
				margin: 101px 0 0 391px;
				z-index: 240;				
			}

			&.--7 {
				/*cucumber top*/
				width: 318px;
				display: block;
				margin: -410px 0 0 216px;				
			}

			&.--8 {
				/* broccoli */
				width: 411px;
				display: block;
				margin: 26px 0 0 -324px;
				z-index: 260;
			}

			&.--9 {
				/*leaf right*/
				width: 325px;
				display: block;
				margin: -61px 0 0 392px;
			}

			&.--10 {
				/*leaf top*/
				width: 283px;
				display: block;
				margin: -464px 0 0 -258px;
			}

			&.--11 {
				/*leaf middle*/
				width: 601px;
				display: block;
				margin: -216px 0 0 54px;
			}

			&.--12 {
				/* Dill and 2 peppers*/
				width: 550px;
				display: block;
				margin: 256px 0 0 -326px;
				z-index: 270;
				
			}

			&.--13 {
				/*leaf left*/
				width: 388px;
				display: block;
				margin: 178px 0 0 -335px;
				z-index: 280;

			}

		}


	}

}

.select2-results__option {
	padding: 12px 22px 8px;
	color: $black;
	font-weight: 600;
	font-size: 24px;
	line-height: 26px;
	user-select: none;
	-webkit-user-select: none;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
	background-color: $red;
	color: white;
}

.select2-container--default .select2-results__option--selected {
	background-color: #ebebeb;
	color: $black;
}

.select2-dropdown {
	border: none;
}

.select2-container--open .select2-dropdown--below {
	border-top: #CCC solid 1px;
}

.select2-container--open .select2-dropdown--above {
	border-bottom: #CCC solid 1px;
}

.select2-container--default .select2-results>.select2-results__options {
	max-height: 250px;
}


@media screen and (max-width: 1440px) {

	h1, .h1 {
		font-size: 84px;
		line-height: 96px;
	}

}

@media screen and (max-width: 1170px) {

	h1, .h1 {
		font-size: 78px;
		line-height: 95px;
	}

}

@media screen and (max-width: 992px) {

	h1, .h1 {
		font-size: 58px;
		line-height: 66px;
	}

	.parallax-vegetables {
		background-size: 100% auto;
	}

}

@media screen and (max-width: 768px) {

	h1, .h1 {
		font-size: 42px;
		line-height: 50px;
	}

	.parallax-vegetables {
		background-size: 150% auto;
	}

}

@media screen and (max-width: 576px) {

	::-webkit-input-placeholder {
		font-size: 20px;
		line-height: 22px;
	}

	::-moz-placeholder {
		font-size: 20px;
		line-height: 22px;
	}

	::-ms-placeholder {
		font-size: 20px;
		line-height: 22px;
	}

	::placeholder {
		font-size: 20px;
		line-height: 22px;
	}

	.btn {
		padding: 19px 45px 16px;
		font-size: 24px;
		line-height: 26px;		
	}

	.parallax-vegetables {
		background-size: 170% auto;
		background-position: center -30%;
	}

	.select2-results__option {
		padding: 12px 22px 8px;
		font-size: 20px;
		line-height: 22px;
	}

}
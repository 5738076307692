$white: #FFFFFF;
$black: #000000;
$red: #F60000;
$darkred: #E10A0A;
$darkgrey: #6A6A6A;

$standart-text-color: #FFFFFF;

$hamburger-layer-color: $white;

$grid-breakpoints: (
  xs: 0,
  sm: 575.99px,
  md: 767.99px,
  lg: 991.99px,
  xl: 1169.99px,
  xxl: 1439.99px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px
);

//$grid-columns: 24 !default;
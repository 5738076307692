.footer {

	padding: 48px 0;
	color: $white;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 23px;
	/*background: rgb(7,185,116);
	background: -moz-linear-gradient(111deg, rgba(7,185,116,1) 0%, rgba(71,178,151,1) 49%, rgba(195,122,172,1) 70%, rgba(251,69,52,1) 100%);
	background: -webkit-linear-gradient(111deg, rgba(7,185,116,1) 0%, rgba(71,178,151,1) 49%, rgba(195,122,172,1) 70%, rgba(251,69,52,1) 100%);
	background: linear-gradient(111deg, rgba(7,185,116,1) 0%, rgba(71,178,151,1) 49%, rgba(195,122,172,1) 70%, rgba(251,69,52,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#07b974",endColorstr="#fb4534",GradientType=1);*/
	background-image: url('../images/footer/footer_bg.png');
	background-size: 100% 100%;

	&__content {
		margin-bottom: 30px;
	}

	&__apps {

		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 30px;

		&-item {

			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			margin-right: 4px;

			&-break {
				flex-basis: 100%;
				display: none;
			}

			&:first-child {
				margin-right: 40px;
			}

			&:last-child {
				margin-right: 0px;
			}

			& a {

				display: inline-block;

				& picture,
				& picture img {
					display: inline-block;
				}

			}

		}

	}

	&__copyright {

		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: $white;

		& a {

			text-decoration: none;
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
			color: $white;
			display: inline-block;

			& picture,
			& picture img {
				display: inline-block;
			}

			&:hover {
				text-decoration: underline;
			}

		}

		&-left {

		}

		&-right {
			margin-left: auto;
		}

	}

}

@media screen and (max-width: 768px) {

	.footer {

		&__apps {

			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 30px;

			&-item {

				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
				margin-right: 4px;
				margin-bottom: 5px;

				&-break {
					display: block;
				}

				&:first-child {
					margin-right: 0px;
					flex: 0 0 100%;
					width: 100%;
					margin-bottom: 12px;
				}

				&:last-child {
					margin-right: 0px;
				}

			}

		}

		&__copyright {

			&-left {
				order: 2;
			}

			&-right {
				order: 1;
				width: 100%;
				margin-bottom: 40px;
			}

		}

	}

}
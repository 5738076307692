.faq {

	&__accordion {

		padding: 65px 0 38px;
		background-size: 100% 100%;
		background-image: url('../images/gradient_bg_1.png');

		&-item {

			margin-bottom: 13px;

			& .accordion-item {

				&__head {

					padding: 36px 155px 36px 55px;
					border-radius: 6px;
					background: rgba(255, 255, 255, 0.2);
					font-weight: 700;
					font-size: 24px;
					line-height: 26px;
					color: $white;
					cursor: pointer;
					position: relative;
					-webkit-transition: background .25s ease;
					   -moz-transition: background .25s ease;
					    -ms-transition: background .25s ease;
					     -o-transition: background .25s ease;
					        transition: background .25s ease;

					&:after {
						content: '';
						display: block;
						position: absolute;
						z-index: 100;
						width: 26px;
						height: 16px;
						right: 77px;
						top: 50%;
						transform: translateY(-50%);
						background: url('../img/accordion-arrow-down.svg') 0 0 no-repeat;
						-webkit-transition: all .55s ease;
						   -moz-transition: all .55s ease;
						    -ms-transition: all .55s ease;
						     -o-transition: all .55s ease;
						        transition: all .55s ease;
					}

					&:hover {
						background: rgba(255, 255, 255, 0.45);
					}

				}

				&__content {

					overflow: hidden;
					max-height: 0;

					& p {						
						padding: 24px 55px 11px;
						font-weight: 400;
						font-size: 24px;
						line-height: 26px;
						color: $white;
					}

				}

			}

			&.--active {
			}

			&.--active .accordion-item {

				&__head {

					background: $red;

					&:hover {
						background: $red;
					}

					&:after {
						-webkit-transform: translateY(-50%) rotate(180deg);
						   -moz-transform: translateY(-50%) rotate(180deg);
						    -ms-transform: translateY(-50%) rotate(180deg);
						     -o-transform: translateY(-50%) rotate(180deg);
						        transform: translateY(-50%) rotate(180deg);
					}

				}

				&__content {
					max-height: 500px;
					-webkit-transition: max-height .55s ease;
					   -moz-transition: max-height .55s ease;
					    -ms-transition: max-height .55s ease;
					     -o-transition: max-height .55s ease;
					        transition: max-height .55s ease;
				}

			}

		}

		&-feedback {

			& a {

				font-weight: 400;
				font-size: 18px;
				line-height: 23px;
				text-decoration-line: underline;
				color: $white;

				&:hover {
					text-decoration: none;
				}

			}

		}

	}

}



@media screen and (max-width: 1170px) {

}



@media screen and (max-width: 992px) {

}



@media screen and (max-width: 768px) {

	.faq {

		&__accordion {

			padding: 34px 0 59px;

			&-item {

				margin-bottom: 6px;

				& .accordion-item {

					&__head {

						padding: 16px 60px 16px 24px;
						font-size: 20px;
						line-height: 22px;
						font-weight: 600;

						&:after {
							right: 16px;
						}

					}

					&__content {

						overflow: hidden;
						max-height: 0;

						& p {						
							padding: 16px 29px 11px 24px;
							font-weight: 400;
							font-size: 18px;
							line-height: 20px;
						}

					}

				}

			}

			&-feedback {

				margin-top: 36px;

				max-width: 73%;

				& a {
					font-size: 16px;
					line-height: 21px;
				}

			}

		}

	}

}
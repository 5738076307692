.--car-light-blink-twice {
	animation: blink-twice .5s linear;
}

@keyframes blink-twice {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.--car-light-blink-secondly {
	animation: blink-secondly 1s infinite linear;
}

@keyframes blink-secondly {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}